import * as L from "leaflet";
import {Layer} from "leaflet";

export const mapIcon = () =>  L.icon({
    iconUrl: '/static/map-marker.svg',
    iconSize: [32, 49],
    iconAnchor: [16, 48],
    popupAnchor: [0, -49]
});

export const mapMarker = (lat: number, lng: number, alt: string = '') => {
    const icon = mapIcon();

    return L.marker([lat, lng], { icon, alt});
}

export const map = (lat: number, lng: number, zoom: number = 7) => {
    const options: L.MapOptions = {
        preferCanvas: true,
        minZoom: 4,
    };

    const map = L.map('map-target', options)
        .setView([lat, lng], zoom );

    L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    })
        .addTo(map);

    return map;

}

export const centerMapToMarker = (llMap: L.Map, markers:  Layer[]) => {
    // @ts-ignore
    const group: any = new L.featureGroup(markers);

    llMap
        .invalidateSize()
        .fitBounds(group.getBounds());
}