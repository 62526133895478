
export const LAYOUT_BREAKPOINT_SMALL = 700;
export const LAYOUT_BREAKPOINT_MEDIUM = 900;
export const LAYOUT_BREAKPOINT_LARGE = 1100;

export const HIDDEN_CLASS_NAME = 'hidden'
export const URL_PARAM_LAT = 'lat'
export const URL_PARAM_LNG = 'lng'
export const URL_PARAM_SEARCH = 'search'
export const API_VERSION = '/v1'
export const API_STATIC_MEMBER_JSON = `/members.json`;
export const API_ZIP_SEARCH = `${API_VERSION}/completion`;
export const API_CONSULTANT_SEARCH = `${API_VERSION}/member`;
export const API_CONTACT = `${API_VERSION}/contact`;
export const API_CONTACT_CHALLENGE = `${API_VERSION}/challenge`;