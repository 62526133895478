import { HTMLAnchorElement, MouseEvent, TouchEvent } from 'happy-dom';

const imageModal = () => {
    const teasers = document.querySelectorAll('.modal-teaser');
    const tmpDiv = document.createElement('div') as HTMLDivElement;
    const modalTemplate = `
        <div class="modal-overlay full-screen" id="modal">
            <div class="modal-content loading">
                <div class="loading-spinner"></div> 
                <button class="modal-close" aria-label="Close Modal">&times;</button>
                <img alt="Modal Image" id="modal-img">
            </div>
        </div>
    `;

    tmpDiv.innerHTML = modalTemplate;
    document.body.appendChild(tmpDiv);

    const modal = document.getElementById('modal') as HTMLElement;
    const modalContent = document.querySelector('.modal-content') as HTMLDivElement;
    const modalImage = tmpDiv.querySelector('img') as HTMLImageElement;
    const modalClose = document.querySelector('.modal-close') as HTMLButtonElement;

    let isDragging = false;
    let startX: number, startY: number;
    let initialTranslateX = 0;
    let initialTranslateY = 0;
    let scale = 2;
    let startDistance = 0;

    function openModal(event: MouseEvent) {
        event.preventDefault();
        modalImage.src = (event.currentTarget as HTMLAnchorElement).getAttribute('href') || '';
        modal.classList.add('active');
        modalContent.classList.remove('loaded');
        modalContent.classList.add('loading');

        // Wait until modalImage has fully loaded
        modalImage.onload = () => {
            modalContent.classList.remove('loading');
            modalContent.classList.add('loaded');
            modalImage.classList.add('zoom-hint');
            modalImage.addEventListener('animationend', removeZoomHint, { once: true });
        };
    }

    function removeZoomHint() {
        if (window.innerWidth <= 1024) {
            modalImage.style.transform = `scale(${2})`;
        }
        modalImage.classList.remove('zoom-hint');
    }

    function closeModal() {
        modal.classList.remove('active', 'zoomed');
        resetImagePosition();
    }

    function resetImagePosition() {
        modalImage.style.transform = 'translate(0, 0) scale(1)';
    }

    modalClose.addEventListener('click', closeModal);
    modalClose.addEventListener('touchstart', closeModal);

    if (window.innerWidth > 768) {
        teasers.forEach(item => item.addEventListener('click', openModal));
    }

    function handleImageClick(event: MouseEvent) {
        const deltaX = Math.abs(event.clientX - startX);
        const deltaY = Math.abs(event.clientY - startY);

        startX = event.clientX - modalContent.offsetLeft;
        startY = event.clientY - modalContent.offsetTop;

        const imageWidth = modalImage.naturalWidth;
        const imageHeight = modalImage.naturalHeight;

        const targetX = imageWidth / modalImage.clientWidth * startX;
        const targetY = imageHeight / modalImage.clientHeight * startY;

        isDragging = false;

        if (deltaX < 5 && deltaY < 5) {
            modalImage.classList.toggle('zoomed');

            if (modalImage.classList.contains('zoomed')) {
                let translateX = -(targetX - modalContent.clientWidth / 2);
                let translateY = -(targetY - modalContent.clientHeight / 2);

                translateX = Math.max(Math.min(translateX, 0), modalContent.clientWidth - imageWidth);
                translateY = Math.max(Math.min(translateY, 0), modalContent.clientHeight - imageHeight);

                modalImage.style.transform = `translate(${translateX}px, ${translateY}px)`;
            } else {
                resetImagePosition();
            }
        }
    }

    function handleBackgroundClick(event: MouseEvent) {
        const modalContent = modal.querySelector('.modal-content');
        if (!modalContent.contains(event.target as Node) && !isDragging) {
            closeModal();
        }
    }

    function startImageDrag(event: MouseEvent) {
        startX = event.clientX;
        startY = event.clientY;

        if (!modalImage.classList.contains('zoomed')) return;

        isDragging = true;

        const matrix = window.getComputedStyle(modalImage).transform;
        if (matrix !== 'none') {
            const values = matrix.split('(')[1].split(')')[0].split(',');
            initialTranslateX = parseFloat(values[4]);
            initialTranslateY = parseFloat(values[5]);
        }
    }

    function moveImage(event: MouseEvent) {
        if (isDragging) {
            const deltaX = event.clientX - startX;
            const deltaY = event.clientY - startY;

            const mouseMoveX = initialTranslateX + deltaX;
            const mouseMoveY = initialTranslateY + deltaY;

            const newTranslateX = Math.max(Math.min(mouseMoveX, 0), modalContent.clientWidth - modalImage.scrollWidth);
            const newTranslateY = Math.max(Math.min(mouseMoveY, 0), modalContent.clientHeight - modalImage.scrollHeight);

            modalImage.style.transform = `translate(${newTranslateX}px, ${newTranslateY}px)`;
        }
    }

    modalContent.addEventListener('mousedown', startImageDrag);
    modalContent.addEventListener('mousemove', moveImage);

    modalImage.addEventListener('mousedown', startImageDrag);
    modalImage.addEventListener('click', handleImageClick);
    modalImage.ondragstart = () => false;

    modal.addEventListener('click', handleBackgroundClick);


    /// Tablet Section Below :)

    let isPinching = false;

    function startTouch(event: TouchEvent) {
        modalImage.classList.remove('zoom-hint');
        event.preventDefault();

    if (event.touches.length === 2) {
        isPinching = true;
        startDistance = Math.hypot(
            event.touches[0].clientX - event.touches[1].clientX,
            event.touches[0].clientY - event.touches[1].clientY
        );
        scale = parseFloat(window.getComputedStyle(modalImage).transform.split(',')[3]) || 1;
    }

        if (scale >= 1 && (event.touches.length === 1 || event.touches.length === 2)) {
            isDragging = true;
            startX = event.touches[0].clientX;
            startY = event.touches[0].clientY;

            const matrix = window.getComputedStyle(modalImage).transform;
            if (matrix !== 'none') {
                const values = matrix.split('(')[1].split(')')[0].split(',');
                initialTranslateX = parseFloat(values[4]);
                initialTranslateY = parseFloat(values[5]);
            }
        }
    }

    function moveTouch(event: TouchEvent) {
        event.preventDefault();
        if (isPinching && event.touches.length === 2) {
            const currentDistance = Math.hypot(
                event.touches[0].clientX - event.touches[1].clientX,
                event.touches[0].clientY - event.touches[1].clientY
            );

            const maxScale =  modalImage.naturalWidth / modalContent.clientWidth

            const scaleChange = currentDistance / startDistance;
            scale = Math.max(1, Math.min(maxScale, scale * scaleChange));

            modalImage.style.transform = `translate(${initialTranslateX}px, ${initialTranslateY}px) scale(${scale})`;
            startDistance = currentDistance;
        }

        if (isDragging && (event.touches.length === 1 || event.touches.length === 2)) {
            const deltaX = event.touches[0].clientX - startX;
            const deltaY = event.touches[0].clientY - startY;

            const newTranslateX = Math.max(
                Math.min(initialTranslateX + deltaX, (modalContent.clientWidth * (scale - 1)) / 2),
                -(modalContent.clientWidth * (scale - 1)) / 2
            );

            const newTranslateY = Math.max(
                Math.min(initialTranslateY + deltaY, (modalContent.clientHeight * (scale - 1)) / 2),
                -(modalContent.clientHeight * (scale - 1)) / 2
            );

            modalImage.style.transform = `translate(${newTranslateX}px, ${newTranslateY}px) scale(${scale})`;
        }
    }

    function endTouch(event: TouchEvent) {
        if (event.touches.length < 2) {
            isPinching = false;
            isDragging = false;
        }
    }

    modalContent.addEventListener('touchstart', startTouch);
    modalContent.addEventListener('touchmove', moveTouch);
    modalContent.addEventListener('touchend', endTouch);
};

export default imageModal;
