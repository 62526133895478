import { HTMLElement } from 'happy-dom';

const footnotes = () => {
    const target = document.getElementById('footnotes');
    const footnotes = document.getElementsByClassName('footnote');

    const generateLink = (id: string, num: number) => `<a href="#${id}" class="footnote-link">${num}</a>`;
    const generateFootnote = (id: string, num: number, cnt: string) => `<li id="${id}" data-num="${num}">${cnt}</li>`;

    const modifyFootnote = (footnote: HTMLElement, num: number ) => {
        const parent = footnote.parentElement;

        const id = `fn-${num}`;
        target.innerHTML += generateFootnote(id, num, footnote.innerHTML);
        parent.removeChild(footnote);

        parent.innerHTML += generateLink(id, num);
    }

    const l = footnotes.length;
    for(let i = 0; i < l;) {
        const footnote = footnotes[0] as unknown as HTMLElement;
        i++
        modifyFootnote(footnote, i);
    }

}

export default footnotes;