import {map, mapMarker} from "../helper/map";
import {Map} from "leaflet";
import {LAYOUT_BREAKPOINT_SMALL} from "../constant";

const consultantLocation = async () => {
    const mapTarget = document.getElementById('map-stripe-target') as HTMLDivElement;
    const [lat, lng] = mapTarget.dataset!.latlong!.split(';').map(Number);
    const shift = window.innerWidth > LAYOUT_BREAKPOINT_SMALL ? 0.0035 : 0;
    let llMap: Map;


    const renderMap = () => {
        llMap = map(lat + 0.0004, lng + shift, 16)
    }

    const renderMapIcon = () => {
        mapMarker(lat, lng)
            .addTo(llMap);
    }


    renderMap()
    renderMapIcon();
};

export default consultantLocation;