const faq = () => {
    const aside = document.getElementById('faq-menu');
    const links = aside.querySelectorAll('a');

    const handleLinkClick = (e) => toggleClasses(e.currentTarget.href)

    const toggleClasses = (href) => {
        for (let i = 0; i < links.length; i++) {
            const link = links[i];

            link.classList.toggle('active', link.href.indexOf(href) !== -1);
        }
    }

    const handleObserve = (entries, observer) => {
        entries.forEach((entry, i) => {
            if (entry.isIntersecting) {
                toggleClasses(`#${entry.target.id}`)
            }
        })
    }

    const observer = new IntersectionObserver(handleObserve, {
        threshold: [0],
    });

    for (let i = 0; i < links.length; i++) {
        const link = links[i];
        const [, id] = link.href.split('#');
        const section = document.getElementById(id);
        link.addEventListener('click', handleLinkClick);
        observer.observe(section);
    }
}

export default faq;