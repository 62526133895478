
export const api = (reqHeader?: HeadersInit) => {
    const headers: HeadersInit = reqHeader || {
        'Content-Type': 'application/json'
    }

    const request = async (url: string, body?: any, method: string = 'POST') => {
        const payload: RequestInit = {
            method,
            headers,
        }
        body && (payload.body = body);

        return await fetch(url, payload)
            .then(async (res) => ({
                ok: res.ok,
                status: res.status,
                data: res.status === 204 ? null : await res.json()
            }))
            .catch(async (err: Error) => {
                console.error( err );
                return {
                    ok: false,
                    status: 500,
                    data: err,
                }
            });
    }

    return {
        request,
    }
}