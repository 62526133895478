import "../styles/index.scss"

/**
 * @constructor
 */
(() => {

    import('./icon-loader')
        .then((obj) => obj.default());

    const contactEl = document.getElementById('contact-form');
    contactEl && import('./contact')
        .then((obj) => obj.default());

    const introEl = document.getElementById('intro');
    introEl && import('./intro')
        .then((obj) => obj.default());

    const faqEl = document.getElementById('faq');
    faqEl && import('./faq')
        .then((obj) => setTimeout(() => obj.default(), 200)); //TODO: Remove timeout when content comes from cms

    const footnotesEl = document.getElementById('footnotes');
    footnotesEl && import('./footnotes')
        .then((obj) => obj.default());

    const consultantDetailEl = document.getElementById('map-stripe-target');
    consultantDetailEl && import('./shop-detail/index')
        .then((obj) => obj.default());

    const modalTeaserEl = document.getElementsByClassName('modal-teaser');
    modalTeaserEl.length && import('./image-modal/index')
        .then((obj) => obj.default());

    setTimeout(() => document.body.classList.remove('preload'), 1);
})()
