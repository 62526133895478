const intro = () => {
    const scrollToId = 'introScrollTarget';
    const scrollIndicator = document.getElementById('scrollIndicator') as HTMLAnchorElement;
    const videoWrp = document.getElementById('intro') as HTMLDivElement;
    const video = videoWrp.getElementsByTagName('video')[0] as HTMLVideoElement;
    const btn = document.getElementById('intro-trigger') as HTMLVideoElement;

    videoWrp.nextElementSibling.id = scrollToId;
    scrollIndicator.href = `#${scrollToId}`;

    btn.addEventListener('click', () => {
        video && video.play();
        btn.parentElement.removeChild(btn);
    });
    btn.click();

}

export default intro;