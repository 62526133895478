const iconLoader = async () => {
    const src = 'https://cdn.dev.electronicpartner.io/ui/font/icons-outlined.woff2';
    const init: RequestInit = {
        method: 'GET',
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
        mode: 'no-cors',
    };

    const setBodyClass = ( ) => {
        document.body.classList.add('icons-loaded')
    }

    await fetch(src, init)
        .then(setBodyClass)
        .catch(setBodyClass);

}

export default iconLoader;